/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'reload': {
    width: 36,
    height: 28,
    viewBox: '0 0 36 28',
    data: '<path pid="0" d="M31.423 12.556a13.476 13.476 0 01-3.877 10.99c-5.273 5.272-13.82 5.272-19.092 0a.75.75 0 111.06-1.06c4.687 4.686 12.285 4.686 16.97 0a11.978 11.978 0 003.444-9.803l-2.885 2.88a.75.75 0 01-1.06-1.06l2.908-2.905a2.625 2.625 0 013.711.001l2.916 2.914a.75.75 0 11-1.06 1.06l-2.916-2.913a1.134 1.134 0 00-.12-.104zM4.576 15.444a13.476 13.476 0 013.878-10.99c5.272-5.272 13.82-5.272 19.091 0a.75.75 0 01-1.06 1.06C21.799.829 14.2.829 9.515 5.515a11.977 11.977 0 00-3.444 9.803l2.886-2.88a.75.75 0 111.06 1.06l-2.909 2.905a2.625 2.625 0 01-3.711-.001L.482 13.487a.75.75 0 011.06-1.06l2.915 2.913c.038.038.078.072.12.104z" _fill="#fff"/>'
  }
})
